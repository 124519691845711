import React, { Component } from 'react';
import { Layout } from 'layouts';
import {
  PageHeader,
  TechnologyPartners,
  BrandsWorkedWithUs,
} from '@components';
import {
  BetterThanOther,
  MultiColumnList,
  MultiColumnContent,
} from '@components/industries';
import Heading from '@components/pageHeading';
import PageNavigation from '@components/pageNavigation';
import pageContent from '@constants/industries';

class Automobile extends Component {
  render() {
    const automobile = pageContent.subPage.automobile;
    return (
      <Layout>
        <PageHeader image={automobile.headerImage} />

        <Heading
          title={automobile.title}
          overview={automobile.overview}
          noHeadingIcon={true}
        />

        <BetterThanOther content={automobile.betterThanOther} />

        <MultiColumnContent
          highlightColumnTitle={true}
          content={automobile.challenges}
        />

        <MultiColumnList content={automobile.solutions} />

        <PageNavigation
          prev={pageContent.navigation.prev}
          next={pageContent.navigation.next}
        />

        <TechnologyPartners />

        <BrandsWorkedWithUs />
      </Layout>
    );
  }
}

export default Automobile;
